import { Link } from "gatsby"
import React from "react"

export default function Card({ icon, to, title, description, reverse }) {
  return (
    <div className="bg-stone-50 flex flex-col gap-3 p-4 relative">
      <span
        className={`w-12 h-12 rounded  flex justify-center items-center text-2xl ${
          reverse ? "bg-black text-white" : "bg-white text-secondary"
        }`}
      >
        {icon}
      </span>
      <div className=" flex-grow">
        <div className="px-2 flex flex-col gap-2">
          <h3 className="text-2xl font-semibold overflow-hidden">{title}</h3>
          <p className="text-sm text-stone-500">{description}</p>
        </div>
      </div>
      <div>
        <Link
          to={to}
          className="px-6 py-2 bg-secondary text-primary font-medium text-xs"
        >
          Learn more
        </Link>
      </div>
    </div>
  )
}

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { RiStarLine, RiUser2Line, RiFootballLine } from "react-icons/ri"
import Card from "../section/card"
import Grid from "../section/grid"
export default function Service() {
  return (
    <section className="min-h-screen my-12">
      <div className="max-w-7xl w-11/12 2xl:w-full mx-auto">
        <div className=" flex flex-col gap-3 items-center">
          <h3 className="text-2xl md:text-4xl font-serif capitalize">
            Tony's <br />
            <span className="text-3xl md:text-5xl text-secondary ">
              services
            </span>
          </h3>
          {/* <p className="max-w-lg text-center">
            While we can customize your needs plan to suit your needs, most
            clients schedule regular class
          </p> */}
        </div>
        <div className="grid sm:grid-cols-3  gap-5 lg:gap-16 my-5 lg:my-16">
          {services.map((service, key) => {
            return <Card key={key} {...service} />
          })}
        </div>

        <Grid
          image={
            <StaticImage
              src="../../images/tony.png"
              className="h-full w-full object-cover"
              alt="spirit"
            />
          }
        >
          <h3 className="text-2xl lg:text-4xl font-serif">
            Tony Womersley is an English-born gifted medium, energy healer, and
            spiritual teacher.
          </h3>
          <p className="text-stone-500 text-xs  lg:text-base">
            Before stepping into his life's purpose, Tony was an engineer, and
            he found himself unfulfilled in both his personal life and work.
            Mentally and physically, he experienced a personal rock bottom. In
            2019 Tony had a massive spiritual awakening that changed his life
            forever. He was lying in bed on a Sunday night, he looked to the
            bedside table to see his deceased father's spirit in 3D form, and
            his father was shining bright white. Tony then looked to the end of
            the bed, where he saw six spirits in 3D form standing in a circle.
          </p>
          <p className="text-stone-500 text-xs  lg:text-base">
            Since that night, Tony’s been able to see spirit with his eyes open
            and through his third eye. Tony has been gifted with all the psychic
            senses; he can see, hear, feel and sense spirit.
          </p>
          <p className="text-stone-500 text-xs  lg:text-base">
            At the age of 12, Tony suffered the devastating loss of his father.
            There was no cause of death as he died in their family home. Tony
            couldn’t speak about this loss for many years, so seeing his
            father's spirit brought him a lot of peace and prompted his healing
            journey.
          </p>
          <p className="text-stone-500 text-xs  lg:text-base">
            Tony now heals and guides others through the power of mediumship and
            energy healing to provide loving, supportive and life-changing
            guidance to others. He helps clients on their path of healing and
            personal growth.
          </p>
          <div>
            <Link
              to="/about-tony"
              className="px-6 py-2 bg-secondary text-sm text-primary"
            >
              Learn more
            </Link>
          </div>
        </Grid>
        {/* <Grid
          reverse
          image={
            <StaticImage
              src="../../images/bekir-donmez-eofm5R5f9Kw-unsplash.jpg"
              className="h-full w-full object-cover"
              alt="spirit"
            />
          }
        >
          <h3 className="text-2xl lg:text-4xl font-serif capitalize">
            What you think, <br /> you become.
          </h3>
          <h3 className="text-2xl lg:text-4xl font-serif capitalize">
            What you feel, <br /> you attract.
          </h3>
          <h3 className="text-2xl lg:text-4xl font-serif capitalize">
            What you imagine, <br /> you create.
          </h3>
          <p> -Buddha</p>
          <div>
            <Link
              to="/services"
              className="px-6 py-2 bg-primary text-sm text-secondary"
            >
              Learn more
            </Link>
          </div>
        </Grid> */}
        {/* <div className="my-5 lg:my-16 grid ">
          <div className="bg-stone-200 md:col-start-1 md:row-start-1 md:relative md:z-0 aspect-video overflow-hidden">
            <StaticImage
              src="../../images/content-pixie-aIdgpT8Hokg-unsplash.jpg"
              alt="wide"
              className="w-full object-cover h-full"
            />
          </div>
          <div className="md:p-3 lg:p-6 md:col-start-1 md:row-start-1 md:relative md:z-10 bg-black/30">
            <div className=" md:flex md:justify-end md:items-center h-full">
              <div className="md:w-1/2 lg:w-1/3 md:h-5/6 lg:h-3/4 bg-primary md:mr-8 lg:mr-16 p-4 flex flex-col justify-center">
                <h3 className="text-2xl font-semibold text-white">
                  3 Steps to make a class reserversatiom
                </h3>
                <ul className="grid gap-6 md:gap-3 py-4">
                  {steps.map((li, key) => {
                    return (
                      <li key={key} className="relative">
                        <span className="absolute top-1/4 font-serif text-white/10 text-5xl z-0">{`0${
                          key + 1
                        }`}</span>
                        <div className="pl-12 relative z-10">
                          <strong className="text-white">{li.title}</strong>
                          <p className="text-stone-200 text-xs xl:text-sm">
                            {li.description}
                          </p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  )
}

// const steps = [
//   {
//     title: "Call the number on the website",
//     description: "The first step you have to call the number on the website",
//   },
//   {
//     title: "Choose the class you want",
//     description: "After that you can choose the class you want to study",
//   },
//   {
//     title: "Make payments every week",
//     description: "After payment every week you can get a healthy body",
//   },
// ]

const services = [
  {
    icon: <RiStarLine />,
    title: "Mediumship",
    to: "/services/readings/",
    description:
      " A mediumship reading can be exceptionally healing to both you and a passed loved one.",
  },
  {
    icon: <RiStarLine />,
    // reverse: true,
    to: "/services/energy-readings/",

    title: "Energy Healing",
    description:
      "An energy healing session will help your body feel healthy both physically and spiritually.",
  },
  {
    icon: <RiStarLine />,
    to: "/services/general-guidance",

    title: "Spiritual Guidance",
    description:
      "A session will help you navigate through your spiritual awakening and any other areas you need support.",
  },
]

import * as React from "react"

// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import Service from "../components/home/service"
import Testimonial from "../components/home/testimonial"
import { graphql } from "gatsby"
import { Disclosure } from "@headlessui/react"
import Markdown from "markdown-to-jsx"

const IndexPage = ({ data, location }) => (
  <Layout>
    <Seo title="Home" pathname={location.pathname} />
    <Hero
      title="Giving Spirit A Voice"
      description="Channelling healing messages from the spirit world."
    />
    <Service />
    <section className="max-w-7xl mx-auto w-11/12 my-6 2xl:w-full flex flex-col gap-10">
      <div className="flex flex-col gap-4 items-center">
        <h3 className="text-4xl font-serif text-secondary">
          Frequently Asked Questions
        </h3>
        {/* <p className="max-w-lg text-center mx-auto w-full text-sm text-secondary">
            Check this small FAQ, we help you find ansers on any of your
            questions. <br /> Otherwise, you can contact me and ask directly.
          </p> */}
      </div>

      <ul className="flex flex-col gap-4 max-w-4xl w-full mx-auto">
        {data.FAQ.nodes.map((li, key) => (
          <Disclosure
            as="li"
            key={key}
            className="bg-primary rounded-md w-full flex flex-col items-start divide-y divide-secondary/20"
          >
            <Disclosure.Button className="p-4 w-full text-left flex justify-between items-center">
              <span className="font-semibold text-secondary">
                {li.frontmatter.title}
              </span>
              <span className="text-2xl">+</span>
            </Disclosure.Button>
            <Disclosure.Panel className="pl-4 md:pl-8 pr-4 py-4 w-full">
              <Markdown>{li.frontmatter.answer}</Markdown>
            </Disclosure.Panel>
          </Disclosure>
        ))}
      </ul>
    </section>
    <Testimonial testimonials={data.testimonials.nodes} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    FAQ: allMarkdownRemark(filter: { fields: { collection: { eq: "faq" } } }) {
      nodes {
        frontmatter {
          title
          answer
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: {
        frontmatter: { publish: { eq: true } }
        fields: { collection: { eq: "testimonials" } }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          content
          country

          position
        }
      }
    }
  }
`

import React from "react"

export default function Grid({
  reverse,
  children,
  image,
  imageContainerClass,
}) {
  return (
    <div className="grid md:grid-cols-2 my-8">
      <div
        className={`h-full ${
          imageContainerClass ? imageContainerClass : ""
        } overflow-hidden bg-stone-200 ${
          reverse ? "md:col-start-2 md:row-start-1" : ""
        }`}
      >
        {image}
      </div>
      <div className={`flex justify-center flex-col gap-6 p-3 md:p-8`}>
        {children}
      </div>
    </div>
  )
}

import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
export default function Hero({ title, description }) {
  return (
    <section className="bg-stone-100 min-h-screen grid">
      <div className="col-start-1 row-start-1 relative z-0">
        <StaticImage
          src="../../images/conscious-design-Hn4wYHOaeIc-unsplash.jpg"
          className="h-screen w-full object-cover"
          alt="tony-bg"
          transformOptions={{
            grayscale: true,
          }}
        />
      </div>
      <div className="col-start-1 row-start-1 relative z-10 flex items-end md:items-center  py-6 bg-gradient-to-tl to-black/70 from-stone-600/60">
        <div className="max-w-7xl w-11/12 2xl:w-full mx-auto">
          <div className="flex flex-col gap-4 max-w-[52rem]">
            <h1 className="font-serif text-4xl md:leading-[70px] md:text-6xl text-white">
              {title}
            </h1>
            <h2 className="text-2xl md:text-4xl max-w-xl md:leading-[40px] font-serif text-stone-100">
              {description}
            </h2>
            {/* <h2 className="text-stone-400">
              Reiki Energy Healing is actually an exercise of body and mind. The
              benefits of yoga not only help the body become fresher and fitter,
              but also can control stress.
            </h2> */}
            <div className="flex gap-3 flex-wrap">
              <button
                onClick={() => navigate("/book-online")}
                className="px-8 py-3 bg-primary  text-xs font-semibold"
              >
                Book Now
              </button>
              <button
                onClick={() => navigate("/about-tony")}
                className="px-8 py-3 border border-primary text-primary text-xs font-semibold"
              >
                About Tony
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
